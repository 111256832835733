'use client'
import { NavbarItem } from '@nextui-org/navbar'
import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/navigation'

export default function LandingMenuLink({
  href,
  children,
  target,
  onClick,
}: {
  target?: string
  href: string
  children: React.ReactNode
  onClick: () => void
}) {
  const router = useRouter()

  function handleLink(event: any, href: string) {
    event.preventDefault()
    onClick()
    router.push(href)
  }

  return (
    <a
      onClick={() => handleLink(event, href)}
      className={'text-black hover:underline'}
      target={target}
      href={href}
    >{children}</a>
  )
}
