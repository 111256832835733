'use client'
import NextImage, { StaticImageData } from 'next/image'
import logoPng from '@/public/job-mojito-logo.png'
import logoInterview from '@/public/interview-mojito-logo.png'
import Link from 'next/link'
import { useSearchParams } from "next/navigation";
import React, { useEffect, useState } from 'react'

export default function MerchantLogo({
  showLink = true,
}: {
  showLink?: boolean
}): React.JSX.Element {

  useEffect(() => {
    // receive logo from cookies or use default
    const currentFullUrl = window.location.host;
    setLogo( (currentFullUrl.includes('app.interviewmojito.com')? logoInterview: logoPng))
  }, [])
  const [logo, setLogo] = useState<StaticImageData>()

  return (
    <>
      {
      (logo &&
      (showLink ?
        <Link href={'/'}>
          <NextImage
            className={''}
            src={logo}
            sizes="(max-width: 300px) 100vw"
            style={{ objectFit: 'contain', backgroundPositionX: 'left' }}
            alt={'Logo'}
          />
        </Link>
      :
        <NextImage
          className={''}
          src={logo}
          sizes="(max-width: 300px) 100vw"
          style={{ objectFit: 'contain', backgroundPositionX: 'left' }}
          alt={'Logo'}
        />
      ))
      }
    </>
  )
}
